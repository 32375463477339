<template>
  <v-container fluid>
    <h1>Meta Insights</h1>
    <a
      href="https://developers.facebook.com/docs/graph-api/reference/v12.0/insights"
      target="_blank"
      >Meta documentation</a
    >
    <br />
    <br />
    <v-layout column>
      <v-row v-for="(page, p) in insightsPages" :key="p">
        <div>
          <h2>
            {{ page.page_type }}
            page:
            <a :href="`https://facebook.com/${page.page_id}`" target="_blank">{{
              page.page_name
            }}</a>
            <small> (#{{ page.page_id }}) </small>
          </h2>

          <div
            v-for="(timePeriod, t) in new Set(
              requestedInsights
                .filter((r) => r.pageType == page.page_type)
                .map((r) => r.timePeriod)
            )"
            :key="t"
          >
            <Insight
              :showChart="false"
              :showHeader="true"
              :showSummary="false"
              :customerId="customerId"
              :timePeriod="timePeriod"
              :metric="
                page.page_type == 'facebook'
                  ? 'page_impressions'
                  : 'impressions'
              "
              :pageType="page.page_type"
              :pageId="page.page_id"
              :pageName="page.page_name"
            />

            <div
              v-for="(request, j) in requestedInsights.filter(
                (r) =>
                  r.pageType == page.page_type && r.timePeriod == timePeriod
              )"
              :key="j"
            >
              <v-expansion-panels
                v-for="(metric, m) in request.metric"
                :key="m"
                hover
                accordion
                tile
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <Insight
                      :showChart="false"
                      :showHeader="false"
                      :showSummary="true"
                      :customerId="customerId"
                      :timePeriod="request.timePeriod"
                      :metric="metric"
                      :pageType="page.page_type"
                      :pageId="page.page_id"
                      :pageName="page.page_name"
                    />
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Insight
                      :showChart="true"
                      :showHeader="false"
                      :showSummary="false"
                      :customerId="customerId"
                      :timePeriod="request.timePeriod"
                      :metric="metric"
                      :pageType="page.page_type"
                      :pageId="page.page_id"
                      :pageName="page.page_name"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <br />
          <br />
        </div>
      </v-row>
    </v-layout>
    <br />
    <v-divider style="border-width: thin" />
  </v-container>
</template>

<script>
//https://mixedanalytics.com/knowledge-base/import-facebook-page-data-to-google-sheets/
import { mapActions, mapGetters } from "vuex";
import Insight from "@/components/global/Insight";
export default {
  components: {
    Insight,
  },
  data: () => ({
    insightsPages: [],
    customerId: null,

    // time_period_list = ["last_aligned_7days","last_aligned_month","last_aligned_3months","last_aligned_12months"]
    // # metric_list_facebook_page = ['page_fans', 'page_impressions', 'page_impressions_unique',
    // #                     'page_impressions_organic_unique', 'page_engaged_users']
    requestedInsights: [
      {
        timePeriod: "last_aligned_7days",
        metric: [
          "page_impressions",
          "page_fans",
          "page_impressions_unique",
          "page_impressions_organic_unique",
        ],
        pageType: "facebook",
      },

      {
        timePeriod: "last_aligned_month",
        metric: [
          "page_impressions",
          "page_fans",
          "page_impressions_unique",
          "page_impressions_organic_unique",
        ],
        pageType: "facebook",
      },
      {
        timePeriod: "last_aligned_3months",
        metric: [
          "page_impressions",
          "page_fans",
          "page_impressions_unique",
          "page_impressions_organic_unique",
        ],
        pageType: "facebook",
      },

      //// time_period_list = ["last_aligned_7days","last_aligned_month","last_aligned_3months","last_aligned_12months"]
      // # metric_list_instagram = ["impressions" , "reach" , "follower_count", "profile_views"]
      {
        timePeriod: "last_aligned_7days",
        metric: ["impressions", "reach", "follower_count", "profile_views"],
        pageType: "instagram",
      },
      {
        timePeriod: "last_aligned_month",
        metric: ["impressions", "reach", "profile_views"],
        pageType: "instagram",
      },
    ],
  }),
  async created() {
    this.customerId = await this.getCurrentCustomer().id;
    let ret = await this.fetchCustomerInsights({
      id: this.customerId,
      params: {
        get_pages_list: true,
      },
    });
    this.insightsPages = ret;
    // this.insightsPages = [
    //   {
    //     page_id: "17841400603572789",
    //     page_name: "Test1",
    //     page_type: "instagram",
    //   },
    //   {
    //     page_id: "17841400603572789",
    //     page_name: "Test1",
    //     page_type: "facebook",
    //   },
    // ];
  },
  computed: {},
  methods: {
    ...mapActions("insights", ["fetchCustomerInsights"]),
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
  },
};
</script>

<style></style>
