var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h1',[_vm._v("Meta Insights")]),_c('a',{attrs:{"href":"https://developers.facebook.com/docs/graph-api/reference/v12.0/insights","target":"_blank"}},[_vm._v("Meta documentation")]),_c('br'),_c('br'),_c('v-layout',{attrs:{"column":""}},_vm._l((_vm.insightsPages),function(page,p){return _c('v-row',{key:p},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(page.page_type)+" page: "),_c('a',{attrs:{"href":`https://facebook.com/${page.page_id}`,"target":"_blank"}},[_vm._v(_vm._s(page.page_name))]),_c('small',[_vm._v(" (#"+_vm._s(page.page_id)+") ")])]),_vm._l((new Set(
            _vm.requestedInsights
              .filter((r) => r.pageType == page.page_type)
              .map((r) => r.timePeriod)
          )),function(timePeriod,t){return _c('div',{key:t},[_c('Insight',{attrs:{"showChart":false,"showHeader":true,"showSummary":false,"customerId":_vm.customerId,"timePeriod":timePeriod,"metric":page.page_type == 'facebook'
                ? 'page_impressions'
                : 'impressions',"pageType":page.page_type,"pageId":page.page_id,"pageName":page.page_name}}),_vm._l((_vm.requestedInsights.filter(
              (r) =>
                r.pageType == page.page_type && r.timePeriod == timePeriod
            )),function(request,j){return _c('div',{key:j},_vm._l((request.metric),function(metric,m){return _c('v-expansion-panels',{key:m,attrs:{"hover":"","accordion":"","tile":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('Insight',{attrs:{"showChart":false,"showHeader":false,"showSummary":true,"customerId":_vm.customerId,"timePeriod":request.timePeriod,"metric":metric,"pageType":page.page_type,"pageId":page.page_id,"pageName":page.page_name}})],1),_c('v-expansion-panel-content',[_c('Insight',{attrs:{"showChart":true,"showHeader":false,"showSummary":false,"customerId":_vm.customerId,"timePeriod":request.timePeriod,"metric":metric,"pageType":page.page_type,"pageId":page.page_id,"pageName":page.page_name}})],1)],1)],1)}),1)})],2)}),_c('br'),_c('br')],2)])}),1),_c('br'),_c('v-divider',{staticStyle:{"border-width":"thin"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }