<template>
  <v-card elevation="4">
    <v-card-title>
      <v-row justify="space-between">
        <Loader v-if="isLoading" />
        <div v-if="showHeader && insights">
          <small>
            <strong>
              {{ timePeriod }}, since: {{ dateFormatter(insights.since) }},
              until: {{ dateFormatter(insights.until) }}</strong
            ></small
          >
        </div>
        <div v-if="showSummary">
          {{ metric }}: {{ insights && insights.summary }}
        </div>

        <v-btn
          color="success"
          small
          outlined
          v-if="!isLoading && !showSummary && showChart"
        >
          <CopyButton :data="insights.results.join('\n')" text="copy data" />
        </v-btn>
      </v-row>
    </v-card-title>
    <GChart
      v-if="!isLoading && showChart"
      type="LineChart"
      :data="insights.results"
      :options="chartOptions"
    />
  </v-card>
</template>

<script>
import { GChart } from "vue-google-charts";
import CopyButton from "@/components/global/CopyButton";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/global/Loader";

export default {
  components: {
    GChart,
    Loader,
    CopyButton,
  },
  props: {
    showChart: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      required: true,
    },
    timePeriod: {
      type: String,
      required: true,
    },
    metric: {
      type: String,
      required: true,
    },
    pageType: {
      type: String,
      required: true,
      validator: function (value) {
        return ["facebook", "instagram"].indexOf(value) !== -1;
      },
    },
    pageId: {
      type: String,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      insights: {},
      isLoading: null,
      chartOptions: {
        title: "Chart Title",
        subtitle: "Sub Title",
        curveType: "function",
        legend: { position: "none" },
        explorer: { keepInBounds: true },
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.insights = await this.getInsightForPage();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("insights", ["fetchCustomerInsights"]),
    dateFormatter(date) {
      return this.$dayjs.unix(date).format("DD.MM.YYYY");
    },
    async getInsightForPage() {
      let params = {
        page_id: this.pageId,
        page_type: this.pageType,
        metric: this.metric,
        time_period: this.timePeriod,
      };

      let pageInsights = await this.fetchCustomerInsights({
        id: this.customerId,
        params,
      });
      // let pageInsights = {
      //   metric: "impressions",
      //   page_id: "17841400603572789",
      //   page_insights: [
      //     {
      //       description: ".",
      //       id: "17841400603572789/insights/impressions/day",
      //       name: "impressions",
      //       period: "day",
      //       title: "Impressions",
      //       values: [
      //         { end_time: "2022-01-18T08:00:00+0000", value: 0 },
      //         { end_time: "2022-01-19T08:00:00+0000", value: 0 },
      //         { end_time: "2022-01-20T08:00:00+0000", value: 0 },
      //         { end_time: "2022-01-21T08:00:00+0000", value: 0 },
      //         { end_time: "2022-01-22T08:00:00+0000", value: 0 },
      //         { end_time: "2022-01-23T08:00:00+0000", value: 0 },
      //         { end_time: "2022-01-24T08:00:00+0000", value: 0 },
      //       ],
      //     },
      //   ],
      //   page_name: "Test1",
      //   page_type: "instagram",
      //   since: 1642440179,
      //   time_period: "last_aligned_7days",
      //   until: 1643044979,
      // };

      if (!pageInsights) {
        return;
      }
      let header = ["Date", pageInsights.metric];
      let objects = pageInsights.page_insights;
      if (!objects) {
        return;
      }

      let values = objects && objects.map((obj) => obj["values"]).flat();
      let data = values && values.map((v) => [Date(v["end_time"]), v["value"]]);
      let sum =
        data && data.length && data.map((d) => d[1]).reduce((a, b) => a + b);

      return {
        results: [header, ...data],
        since: pageInsights.since,
        until: pageInsights.until,
        summary: sum,
      };
    },
  },
};
</script>
